import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles,
  Hidden,
  Select,
  Box,
  Link
} from '@material-ui/core';
import Page from 'src/components/Page';
import { callFetch, getBearer } from '../../../helper';
import Cookies from 'universal-cookie';
import LeftMenu from '../LeftMenu';
import YearlyReports from '../ReportView/YearlyReports';
import PaymentToDate from './PaymentToDate';
import TopDashboard from './TopDashboard';
import News from './News';
import { CircularProgress } from '@material-ui/core';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    const options = [];
    const cYear = moment('year');

    var today = moment().set({
      date: 1,
      month: 6,
      year: cYear,
      hour: 0,
      minute: 0,
      second: 0
    });
    var endFY = moment().set({
      date: 30,
      month: 5,
      hour: 0,
      minute: 0,
      second: 0
    });
    var sinceYear = moment()
      .subtract(6, 'year')
      .year();

    var endFYYear = endFY.year();
    if (today > endFY) {
      endFYYear = endFY.year() + 1;
    }
    // 7 years

    options.push(
      <option key={'all'} value="All">
        All
      </option>
    );
    for (var i = sinceYear; i <= endFYYear; i++) {
      options.push(
        <option
          key={i}
          value={i - 1 + '-' + i}
          selected={i === endFYYear ? 'selected' : ''}
        >
          {i - 1 + '-' + i}
        </option>
      );
    }

    this.state = {
      keyValid: false,
      passwordSet: false,
      id: 0,
      key: '',
      sponsor: [],
      contractCount: 0,
      contracts: [],
      allContracts: [],
      invoices: [],
      options: options,
      sid: '0',
      dashboard: {},
      selectYear: endFYYear - 1 + '-' + endFYYear,
      isLoading: 1,
      signoutTime: 1000 * 60 * 150
    };

    this.yearChange = this.yearChange.bind(this);
  }
  async isLogin() {
    const navigate = this.props.navigate;
    const bearer = await getBearer();
    const cookies = new Cookies();
    let loginkey = cookies.get('loginkey');
    let request = {};
    request['loginkey'] = loginkey;
    const validation = await callFetch(
      process.env.REACT_APP_API_URL + 'api/user/islogin',
      'POST',
      request,
      bearer
    );
    if (validation.success === 0) {
      navigate('/', { replace: true });
    } else {
      this.setState({
        sponsor: validation.sponsor,
        contractCount: validation.contract_count,
        contracts: validation.contracts,
        allContracts: validation.allContracts,
        invoices: validation.nextInvoices,
        currentYear: validation.currentYear,
        sid: validation.sid,
        isLoading: 0
      });
    }
  }

  componentDidMount() {
    this.isLogin();
    this.events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ];

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();
  }

  setTimeout = () => {
    this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
  };

  resetTimeout = () => {
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    this.setTimeout();
  };

  logout = () => {
    // Send a logout request to the API
    console.log('Sending a logout request to the API...');
    this.destroy();
  };

  destroy = () => {
    //clear the session
    this.props.navigate('/');
    window.location.assign('/');
  };

  yearChange(event, child) {
    this.setState({
      selectYear: event.target.value
    });
  }

  buildReport(year) {
    const navigate = this.props.navigate;
    if (this.state.isLoading === 0) {
      if (this.state.selectYear === 'All') {
        return (
          <div
            style={{
              border: '1px solid #ff853f',
              margin: '0 -16px',
              padding: '16px'
            }}
          >
            <Link
              onClick={() => {
                navigate('/reports', { replace: true });
              }}
              title="Go to report page"
            >
              Go to Reports
            </Link>
          </div>
        );
      } else {
        return (
          <div
            style={{
              border: '1px solid #ff853f',
              margin: '0 -16px',
              padding: '16px'
            }}
          >
            <YearlyReports
              pclasses={this.props.classes}
              sid={this.state.sid}
              year={year}
            />
          </div>
        );
      }
    }
  }

  buildToDate(year) {
    if (this.state.isLoading === 0) {
      return (
        <PaymentToDate
          pclasses={this.props.classes}
          sid={this.state.sid}
          year={year}
        />
      );
    }
  }

  buildTopDashboard(year) {
    if (this.state.isLoading === 0) {
      return (
        <TopDashboard
          dclasses={this.props.classes}
          invoices={this.state.invoices}
          sid={this.state.sid}
          year={year}
        />
      );
    }
  }

  render() {
    const classes = this.props.classes;
    // var contracts = this.state.contracts;
    // var invoices = this.state.invoices;
    // var dashboard = this.state.dashboard;

    return this.state.isLoading ? (
      <div
        style={{
          textAlign: 'center',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <CircularProgress color="success" />
      </div>
    ) : (
      <Page className={classes.root} title="Dashboard">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Hidden smDown>
              <Grid item md={3}>
                <LeftMenu sponsor={this.state.sponsor} />
              </Grid>
            </Hidden>

            <Grid item sm={12} md={9}>
              {/* <Main /> */}
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box
                    mt={3}
                    lineHeight="2"
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="2px solid #ff853f"
                    textAlign="left"
                  >
                    <h3>Sponsorship Dashboard</h3>
                    <Select
                      native
                      variant="standard"
                      onChange={this.yearChange}
                    >
                      {this.state.options}
                    </Select>
                  </Box>
                </Grid>
              </Grid>
              {this.buildTopDashboard(this.state.selectYear)}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box
                    p={2}
                    lineHeight="2"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    //bgcolor="#17a2b8"
                    color="#fff"
                    textAlign="left"
                  >
                    <h4
                      style={{
                        backgroundColor: '#ff853f',
                        padding: '0 16px',
                        marginRight: '-16px',
                        marginLeft: '-16px',
                        borderRadius: '5px 5px 0px 0px'
                      }}
                    >
                      Report
                    </h4>
                    {this.buildReport(this.state.selectYear)}
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box
                    mt={2}
                    lineHeight="2"
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="2px solid #ff853f"
                    textAlign="left"
                  >
                    <h3>Latest News</h3>
                  </Box>
                  <Box mt={2}>
                    <News />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
}

export default () => {
  const navigate = useNavigate();
  const aclasses = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      height: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    boxValues: {
      fontSize: '20px'
    },
    boxHeader: {
      backgroundColor: '#ff853f',
      padding: '0 16px',
      marginRight: '-16px',
      marginLeft: '-16px'
    }
  }));
  return <Dashboard classes={aclasses} navigate={navigate} />;
};
